import PolicyApi from './policy.api.js';

class PolicyService {
  constructor() {
    this.api = new PolicyApi();
  }

  async moderationList(params) {
    const { data } = await this.api.moderationList(params);
    return data;
  }

  async info(id) {
    const { data } = await this.api.info(id);
    return data;
  }

  async file(id) {
    const response = await this.api.file(id);
    return response;
  }

  async moderate(id, body) {
    const { data } = await this.api.moderate(id, body);
    return data;
  }

  async reject(id, body) {
    const { data } = await this.api.reject(id, body);
    return data;
  }
}

export default PolicyService;
