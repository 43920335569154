<template>
  <div class="containerLabsPage relative pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div
          v-if="policy"
          class="title-24-bold-700 flex items-center gap-3"
        >
          <v-icon
            name="l/arrow_left_tail"
            @click="back"
          />
          Информация о полисе № {{ policy.policyNumber }}
        </div>
        <div class="flex flex-col gap-6 mt-6">
          <div
            v-for="item in info"
            :key="item.title"
            class="flex flex-col gap-4"
          >
            <div class="title-16-bold-700">
              {{ item.title }}
            </div>
            <div class="text-16-reg-400">
              {{ item.body }}
            </div>
          </div>
          <div
            v-if="policy?.policyModerationFiles?.length"
            class="flex flex-col gap-1"
          >
            <div class="title-16-bold-700 mb-2">
              {{ fileTitle }}
            </div>
            <DownloadPolicyFileVue
              v-for="file in policy.policyModerationFiles"
              :key="file.id"
              :file="file"
            />
          </div>
          <div class="flex flex-col gap-4">
            <div class="title-16-bold-700">
              Статус модерации
            </div>
            <b-form-select
              v-if="isEdit"
              id="policy-status-moderation"
              v-model="selectedStatusModeration"
              :options="statusOptionsModeration"
              :disabled="!isEdit"
              size="sm"
            />
            <div v-else>
              {{ moderationStatus }}
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div class="title-16-bold-700">
              Комментарий
            </div>
            <textarea
              v-if="isEdit"
              v-model="comment"
              cols="30"
              rows="5"
              class="p-2"
            />
            <div v-else>
              {{ policy?.comment }}
            </div>
          </div>
        </div>
        <div>
          <b-button
            v-if="isEdit"
            class="mr-2 mt-8"
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            :disabled="isDisabledBtn"
            @click="save"
          >
            Сохранить
            <b-spinner
              v-if="false"
              variant="light"
              small
            />
          </b-button>
        </div>
      </b-container>
    </custom-scrollbar>
    <div
      v-if="isLoading"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-v-ui-root-monochrome-10"
    >
      <BSpinner />
    </div>
  </div>
</template>

<script>
import { format } from '@evd3v/date-fns';
import { VIcon } from '@doctis.front/doctis.designsystem';
import PolicyServices from '@/services/policy/policy.service';
import { MODERATION_STATUS } from '@/services/policy/policy.consts';
import BSpinner from '@/components/base/BaseSpinner';
import DownloadPolicyFileVue from '../components/domains/policies/moderation/DownloadPolicyFile.vue';

const policyServices = new PolicyServices();

export default {
  components: {
    VIcon,
    BSpinner,
    DownloadPolicyFileVue,
  },
  data() {
    return {
      isLoading: false,
      policy: null,
      selectedStatusModeration: null,
      statusOptionsModeration: [
        {
          value: null,
          text: 'Выберете статус модерации',
          key: 'OnModeration',
        },
        MODERATION_STATUS[1],
        MODERATION_STATUS[2],
      ],
      comment: '',
    };
  },
  computed: {
    info() {
      if (this.policy) {
        return [
          {
            title: 'Дата и время создания',
            body: format(new Date(this.policy.createDate), 'dd.LL.yyyy kk:mm'),
          },
          {
            title: 'Номер полиса',
            body: this.policy.policyNumber,
          },
          {
            title: 'ФИО пациента',
            body: this.policy.patientFio,
          },
          {
            title: 'Телефон пациента',
            body: this.policy.patientPhoneNumber,
          },
        ];
      }
      return [];
    },
    fileTitle() {
      let title = 'Файл';

      if (this.policy?.policyModerationFiles.length > 1) title += 'ы';

      return title;
    },
    moderationStatus() {
      return MODERATION_STATUS[this.policy?.moderationStatus]?.text;
    },
    isEdit() {
      return this.policy?.moderationStatus === 0;
    },
    isDisabledBtn() {
      return this.selectedStatusModeration === null || !this.comment.length;
    },
  },
  async created() {
    const {
      params: { id },
    } = this.$route;
    try {
      this.isLoading = true;
      const response = await policyServices.info(id);
      this.policy = response.data;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async save() {
      try {
        this.isLoading = true;
        const {
          params: { id },
        } = this.$route;

        const data = { comment: this.comment };

        if (this.selectedStatusModeration === MODERATION_STATUS[1].key) {
          await policyServices.moderate(id, data);
        }
        if (this.selectedStatusModeration === MODERATION_STATUS[2].key) {
          await policyServices.reject(id, data);
        }
        this.back();
      } catch {
        this.$router.go();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#policy-status-moderation {
  width: 25%;
  min-width: 260px;
}
</style>
