export const POLICY_FIELDS = [
  {
    key: 'moderatedAt',
    label: 'Дата',
  },
  {
    key: 'patientId',
    label: 'ID пациента',
  },
  {
    key: 'patientPhoneNumber',
    label: 'Телефон пациента',
  },
  {
    key: 'patientFio',
    label: 'ФИО пациента',
  },
  {
    key: 'moderationStatus',
    label: 'Статус модерации',
  },
  {
    key: 'actions',
    label: 'Подробнее',
  },
];

export const MODERATION_STATUS = {
  0: {
    value: 'OnModeration',
    key: 'OnModeration',
    text: 'На модерации',
  },
  1: {
    value: 'Approved',
    key: 'Approved',
    text: 'Одобрен',
  },
  2: {
    value: 'Rejected',
    key: 'Rejected',
    text: 'Отклонен',
  },
};
