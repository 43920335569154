import { api } from '@/helpers/api';

const PATH = '/v3/policy/moderation';

class PolicyApi {
  constructor() {
    this.api = api;
  }

  async moderationList(params) {
    const response = await this.api.get(`${PATH}/list`, {
      params,
    });

    return response;
  }

  async info(id) {
    const response = await this.api.get(`${PATH}/${id}`);

    return response;
  }

  async file(policyModerationId) {
    const response = await this.api.get(`${PATH}/download-file`, { params: { policyModerationId }, responseType: 'blob' });

    return response;
  }

  async moderate(id, data) {
    const response = await this.api.post(`${PATH}/moderate/${id}`, data, {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    });

    return response;
  }

  async reject(id, data) {
    const response = await this.api.post(`${PATH}/reject/${id}`, data, {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    });

    return response;
  }
}

export default PolicyApi;
